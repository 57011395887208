@import '../../styles/fonts';
@import '../../styles/variables';


.ad-review {
    .review-section {
        margin: 50px 0;

        h3 {
            font-size: 18px;
            color: $gray-dark;
        }

        p {
            font-weight: $font-light;
        }


        .image-grid {
            grid-template-columns: 100px 50px 100px;
            grid-template-rows: 80px auto 80px;
            column-gap: 10px;
            row-gap: 15px;


            .review-image {
                width: 200px;
                height: 200px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }

    p {
        font-size: 15px;
        font-weight: $font-light;
    }

    .form-navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
    
        .back-button,
        .next-button {
            font-family: 'Ubuntu', sans-serif;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            font-weight: $font-light;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .back-button {
            background-color: #ccc;
            color: #333;
        }
    
        .next-button {
            background-color: $two;
            color: $white;
        }
    }
}