@import './fonts';
@import './variables';


.faq-page {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 600px;
    margin: 0 auto;


    .faq-intro {
        margin-top: 7rem;
        text-align: center;
        margin-bottom: 20px;
        padding: 10px;


        h1 {
            font-size: 18px;
            color: $dark;
            font-weight: $font-medium;
            font-family: 'Ubuntu', sans-serif;
        }

        p {
            color: $dark;
            font-weight: $font-light;
            font-family: 'Ubuntu', sans-serif;
        }
    }


    .faq-category {
        margin-bottom: 20px;
        padding: 10px;


        h2 {
            font-size: 18px;
            // padding: 5px 10px;
            color: $dark;
            font-weight: $font-medium;
            font-family: 'Ubuntu', sans-serif;
        }


        .faq-item {
            margin-bottom: 10px;


            h3 {
                font-size: 16px;
                // padding: 5px 10px;
                cursor: pointer;
                color: $dark;
                font-weight: $font-regular;
                font-family: 'Ubuntu', sans-serif;
            }

            p {
                display: none;
                // padding: 5px 10px;
                text-align: justify;
                background-color: #f9f9f9;
                font-family: 'Ubuntu', sans-serif;
                font-weight: $font-light;
            }

            ul {
                list-style-type: none;

                li {
                    font-weight: $font-light;
                }
            }


            .faq-btn-text {
                width: 100px;
                height: 20px;
            }

            .faq-btn-register {
                width: 120px;
                height: 20px;
            }

            .faq-btn {
                /* width: 90px; */
                height: 20px;
            }
        }

        .faq-item.active p {
            display: block;
        }
    }

    .faq-contact {
        padding: 10px;


        h2 {
            font-size: 18px;
            // padding: 5px 10px;
            color: $dark;
            font-weight: $font-medium;
            font-family: 'Ubuntu', sans-serif;
        }

        p {
            font-family: 'Ubuntu',sans-serif;
            font-weight: $font-light;
            text-align: justify;
        }
    }
}