@import '../../styles/fonts';
@import '../../styles/variables';


.ads-connexes {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    // background-color: red;

    h2 {
        margin-top: 32px;
        text-align: center;
        color: $dark;
        font-weight: $font-regular;
    }

    p {
        text-align: center;
        color: $dark;
        font-weight: $font-light;
    }
}