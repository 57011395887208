@import './fonts';
@import './variables';

.signup-page {
    height: 100vh;
    background-color: $two;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .signup-form {
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid $gray;
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
        // width: 400px;
        max-width: 400px;

        @media (max-width: 768px) {
            max-width: 300px;
        }


        .progress-bar {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            
            .step {
                display: flex;
                align-items: center;
                flex-direction: column;

                .bulb {
                    width: 30px;
                    height: 30px;
                    background: #ccc;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active {
                        background: $two;
                        color: $white;
                    }
                }

                .label {
                    margin-top: 5px;
                    font-size: 14px;
                    font-weight: $font-light;

                    &.active {
                        color: $two;
                    }
                }
            }
        }

        .form-group {
            margin-bottom: 20px;

            .form-group-item {
                display: flex;
                flex-direction: column;

                .input-field,
                option {
                    padding: 10px;
                    margin: 10px 0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    text-align: start;
                    outline: none;
                    font-size: 14px;
                    font-family: 'Ubuntu', sans-serif;
                    font-weight: $font-light;
                   
                    &.error {
                        border: 1px solid $danger;
                        background-color: #ffcccb;
                    }
                }

                .error-message {
                    font-weight: $font-light;
                    font-size: 13px;
                    color: $danger;
                }

                .search-field {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .input-field {
                        width: 100%;
                        padding: 10px;
                        margin: 10px 0;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        text-align: start;
                        outline: none;
                        font-size: 14px;
                        font-family: 'Ubuntu', sans-serif;
                        font-weight: $font-light;
                       
                        &.error {
                            border: 1px solid $danger;
                            background-color: #ffcccb;
                        }
                    }

                    .search-icon {
                        position: absolute;
                        top: 18px;
                        right: 10px;
                        color: $dark;
                    }
                }

                .password-field {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .input-field {
                        width: 100%;
                        padding: 10px;
                        margin: 10px 0;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        text-align: start;
                        outline: none;
                        font-size: 14px;
                        font-family: 'Ubuntu', sans-serif;
                        font-weight: $font-light;
                       
                        &.error {
                            border: 1px solid $danger;
                            background-color: #ffcccb;
                        }
                    }

                    .eye-icon {
                        position: absolute;
                        top: 18px;
                        right: 10px;
                        color: $dark;
                        cursor: pointer;
                    }
                }

                .password-strength {
                    display: flex;
                    .strength-bar {
                        width: 100%;
                        padding: 0 5px;
                        color: $white;
                        font-weight: $font-light;
                        font-size: 12px;
                    }

                }

                .confirm-password-field {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .input-field {
                        width: 100%;
                        padding: 10px;
                        margin: 10px 0;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        text-align: start;
                        outline: none;
                        font-size: 14px;
                        font-family: 'Ubuntu', sans-serif;
                        font-weight: $font-light;
                       
                        &.error {
                            border: 1px solid $danger;
                            background-color: #ffcccb;
                        }
                    }

                    .eye-icon {
                        position: absolute;
                        top: 18px;
                        right: 10px;
                        color: $dark;
                        cursor: pointer;
                    }
                }
            }
        }

        .password-toggle {
            position: relative;

            .input-field.error {
                border: 1px solid $danger;
                background-color: #ffcccb;
            }


            span {
                position: absolute;
                top: 55px;
                color: $dark;
                right: 10px;
                cursor: pointer;
            }
        }

        label.checkbox-label {
            font-weight: $font-light;
            padding: 10px 0;
            font-size: 14px;
        }

        .error-text {
            font-size: 13px;
            font-weight: $font-light;
            color: $danger;
        }

        .error {
            font-size: 12px;
            font-weight: $font-light;
            color: $danger;
            margin-top: 10px;
            text-align: center;
        }



        p {
            text-align: center;
            font-size: 14px;
            color: $dark;

            a {
                text-decoration: none;
                color: $primary;
            }
        }

        .agree-label {
            margin-left: 5px;
            font-size: 14px;
            color: $dark;
        }

        .terms-container {
            margin-top: 10px;
            font-size: 14px;
            color: #555;

            a {
                color: $primary;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }

        .form-navigation {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 15px;
        
            .back-button,
            .next-button,
            .submit {
                font-family: 'Ubuntu', sans-serif;
                padding: 8px 16px;
                border: none;
                border-radius: 5px;
                font-weight: $font-light;
                font-size: 14px;
                cursor: pointer;
                transition: all 0.3s;
        
                &:hover {
                    opacity: 0.8;
                }
            }
        
            .back-button {
                background-color: #ccc;
                color: #333;
            }
        
            .next-button {
                background-color: $two;
                color: $white;
            }
            .submit {
                color: $white;
                background-color: $secondary-color;
            }
        }
    }
}