@import './fonts';
@import './variables';


.floating-btn {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: $secondary-color;


    // Media queries
    @media (max-width: 820px) {
        display: flex;
    }

    @media (max-width: 768px) {
        display: flex;
    }

    @media (max-width: 480px) {
        display: flex;
    }


    svg {
        color: $white;
        text-align: center;
        font-size: 24px;
    }
}