@import './fonts';
@import './variables';


.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;


    .error-image {
        width: 300px;
        max-width: 100%;
        margin-bottom: 20px;
    }

    h1 {
        font-size: 6rem;
        color: #dc3545;
        margin: 0;
    }

    h2 {
        font-size: 2.5rem;
        margin: 20px 0;
    }

    p {
        font-size: 1.2rem;
        color: $dark;
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 1rem;
        color: $white;
        background-color: $two;
        border: none;
        border-radius: 5px;
        font-family: 'Ubuntu',sans-serif;
        font-weight: $font-light;
        cursor: pointer;

        &:hover {
            background-color: $three;
        }
    }
}