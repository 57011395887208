@import './fonts';
@import './variables';


.my-ads {
    padding: 10px;
    background-color: $white;
    margin-top: 5rem;
    border-radius: 12px;


    @media (max-width: 768px) {
        margin-top: 9rem;
    }


    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $dark;
        }

        svg {
            color: $dark;
            cursor: pointer;
        }
    }

    .table-container {
        width: 100%;
        overflow-x: auto;

        @media (max-width: 768px) {
            overflow-x: auto;
            border-radius: 5px;
            padding-bottom: 10px;
        }

        table {
            width: 100%;
            min-width: 600px;
            /* Ajuste la largeur minimale selon tes besoins */
            border-collapse: collapse;
            background-color: $white;

            th,
            td {
                padding: 12px;
                text-align: left;
                font-weight: $font-light;
                border-bottom: 1px solid #ddd;
                white-space: nowrap;
                /* Évite les retours à la ligne */
            }

            thead {
                color: $dark;
                background-color: #f5f5f5;

                th {
                    text-transform: uppercase;
                    font-weight: $font-regular;
                }
            }

            tbody tr {
                position: relative;
            }

            tbody tr:nth-child(even) {
                background-color: #f9f9f9;
            }

            tbody tr:hover {
                background-color: #f1f1f1;
            }
        }

        .floating-menu {
            position: absolute;
            background: #f1f1f1;
            border: none;
            border-radius: 50%;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            gap: 8px;
            top: 50%;
            left: 60px;
            transform: translateY(-50%);

            button {
                background: #f1f1f1;
                color: $dark;
                border-radius: 50%;
                border: none;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: background-color 0.3s ease;
                &:hover {
                    background: #f9f9f9;
                }
            }
        }
    }

    .ad-details-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-button {
            background-color: transparent;
            color: white;
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;

            &.approve-button {
                background-color: $two;
            }

            &.reject-button {
                background-color: #f44336;
            }
        }
    }

    .modal-menu {
        .menu-item {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            &:hover {
                background: #f5f5f5;
            }

            svg {
                font-size: 14px;
                color: #666;
            }

            span {
                color: #333;
                font-weight: $font-light;
                // font-size: 13px;
            }
        }

        .menu-item[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 10px 20px;
        background-color: $gray-dark;
        border-radius: 12px;
        margin: 10px 0;

        @media (max-width: 768px) {
            // align-items: flex-start;
            display: flex;
            flex-direction: column;
        }


        input[type=text],
        input[type=date],
        input[type="number"],
        select {
            padding: 10px 8px;
            font-family: 'Ubuntu', sans-serif;
            font-size: 16px;
            font-weight: $font-light;
            outline: none;
        }
    }
}