@import '../../styles/variables';
@import '../../styles/fonts';

.search-bar {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    height: 40px;
    background-color: $white;
    border-radius: 18px;
    border: 1px solid $white;


    input {
        width: 98%;
        height: 88%;
        padding-left: 10px;
        font-size: 16px;
        font-family: "Ubuntu", sans-serif;
        font-weight: $font-light;
        font-style: normal;
        border: 'none';
        border-radius: 18px;
        border: 1px solid $white;
        outline: none;

        &:focus {
            border-color: $two;
        }
    }


    .fa-search {
        position: absolute;
        font-size: 24px;
        right: 10px;
        cursor: pointer;
        color: $gray;
    }

    .suggestions-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        list-style: none;
        margin: 5px 0 0;
        padding: 0;
        max-height: 250px;
        overflow-y: auto;
        z-index: 1000;

        li {
            padding: 10px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: background 0.2s ease-in-out;
            font-size: 14px;

            .sugg-name {
                font-weight: $font-regular;
            }

            .sugg-category {
                color: gray;
                font-size: 12px;
            }

            &:hover {
                background: #f0f0f0;
            }
        }
    }
}