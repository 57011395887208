@import './fonts';
@import './variables';

.store-page {

    .listing {
        margin: 3rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        h3 {
            margin: 0;
            text-transform: uppercase;
            color: $secondary-color;
            font-weight: $font-regular;
        }
        
        span {
            
            color: $dark;
            font-size: 13px;
            font-weight: $font-light;
        }
    }

    .display {
        width: 80%;
        margin: auto;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    form {
        border-top: 1px solid $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        h3 {
            color: $dark;
            font-weight: $font-regular;
        }

        textarea {
            width: 90%;
            padding: 5px;
            font-size: 15px;
            outline: none;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;
            resize: vertical;
        }

        button {
            font-family: 'Ubuntu', sans-serif;
            padding: 10px 20px;
            cursor: pointer;
            background-color: $two;
            color: $white;
            border: none;
            font-weight: $font-regular;
        }
    }
}