@import './fonts';
@import './variables';

.step-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 10px;
        color: $black;
        font-weight: $font-medium;
    }

    .test-details {
        margin: 10px 0;
        padding: 10px;
        background: #f9f9f9;
        border-radius: 4px;
        font-weight: $font-light;
    }

    p {
        font-weight: $font-light;
        text-align: justify;
    }

    .form-group {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 5px;
            font-weight: $font-regular;
        }

        textarea {
            width: 96%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            resize: none;
            min-height: 80px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;
        }

        input[type="radio"] {
            margin-right: 5px;
            font-family: 'Ubuntu', sans-serif;
        }

        div {
            display: flex;
            gap: 10px;
        }
    }

    .navigation-buttons {
        display: flex;
        justify-content: space-between;

        button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            background-color: transparent;
            color: $white;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;

            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }

        }

        .previous-button {
            background-color: $danger;
        }
        .next-button {
            background-color: $two;
        }
        .submit-button {
            background-color: $secondary-color;
        }
    }
}
