@import '../../styles/fonts';
@import '../../styles/variables';

header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: $two;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .container {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .logo {
            margin-left: 10px;
        }

        .actions {
            display: flex;
            align-items: center;
            margin-right: 20px;


            .display-name {
                margin-top: 10px;
                color: $white;
                text-align: center;
                margin-right: 1rem;
            }


            .user-icon {
                position: relative;
                cursor: pointer;


                .icon {
                    font-size: 1.5em;
                    margin-right: 20px;
                    cursor: pointer;
                }

                .dropdown-menu {
                    position: absolute;
                    top: 3em;
                    right: 15px;
                    width: 120px;
                    background-color: $white;
                    border: 1px solid #ccc;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    .dropdown-item {
                        font-weight: $font-light;
                        padding: 8px 12px;
                        text-decoration: none;
                        color: black;
                        cursor: pointer;
                    }

                    .dropdown-item:hover {
                        background-color: #f0f0f0;
                    }

                    .logout {
                        font-weight: $font-light;
                        font-family: 'Ubuntu',sans-serif;
                        background-color: $secondary-color;
                        color: #ffffff;
                        font-size: 15px;
                        padding: 8px 12px;
                        border-radius: 18px;
                        border: none;
                        cursor: pointer;
                    }

                }
            }
        }
    }

}


// .dashboard-header {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 1000;
//     background-color: $two;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

//     // padding: 10px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     color: $white;


//     .right-side {
//         display: flex;
//         align-items: center;
//         justify-content: center;


//         .display-name {
//             text-align: center;
//             margin-right: 1rem;
//         }
//     }
// }