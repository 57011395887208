@import './fonts';
@import './variables';

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $two;

    .suspicious-login-message {
        margin-bottom: 1rem;
        width: 90%;
        text-align: center;
        border-radius: 5px;
        color: $white;
        background-color: #dc3545;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid $gray;
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;

            @media (max-width: 768px) {
                max-width: 300px;
            }


        h2 {
            text-align: center;
            color: $dark;
        }


        label {
            color: $dark;
            display: block;
            font-size: 15px;
            font-weight: $font-regular;
            // margin-top: 1rem;
        }

        label.checkbox-label {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 14px;
            font-weight: $font-light;
        }


        .password-toggle {
            position: relative;


            span {
                position: absolute;
                top: 35px;
                color: $dark;
                right: 1rem;
                cursor: pointer;
            }
        }


        .input-field {
            width: 90%;
            margin: 8px 0;
            padding: 8px 15px;
            display: inline-block;
            border: 1px solid $dark;
            outline: none;
            font-size: 15px;
            color: $dark;
            font-weight: $font-light;
            font-family: "Ubuntu", sans-serif;
        }


        .input-field.error {
            background-color: #FF9494;
            border: 1px solid $red;
        }


        .error-text {
            font-size: 13px;
            font-family: "Ubuntu", sans-serif;
            color: $red;
            margin-bottom: 10px;
            font-weight: $font-regular;
            text-align: start;
        }


        .passwrod-forgot {
            padding: 10px 0;
            text-decoration: none;
            text-align: end;
            font-size: 13px;
            font-weight: $font-light;
        }


        button[type=submit] {
            font-family: 'Ubuntu', sans-serif;
            font-size: 15px;
            border: none;
            padding: 10px;
            color: $white;
            background-color: $secondary-color;
        }

        p {
            text-align: center;
            font-size: 14px;
            color: $dark;

            a {
                text-decoration: none;
                color: $primary;
            }
        }

        .terms-container {
            margin-top: 10px;
            font-size: 14px;
            color: #555;
    
            a {
                color: $primary;
                text-decoration: none;
            }
    
            a:hover {
                text-decoration: underline;
            }
        }
    }
}