@import './fonts';
@import './variables';


.help-layout {
   
    .heading {
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 8px 10px;
        background-color: $white;
        // background-color: #fcfcfc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        img {
            width: 180px;
            height: 100px;
        }
    }

    .help-hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #fcfcfc;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

    
    
        h1 {
            font-size: 32px;
            margin-bottom: 20px;
            color: #333;
    
            // Small devices (landscape phones, less than 768px)
            @media (max-width: 768px) {
                font-size: 24px;
            }
        }
    
    
        p {
            font-size: 18px;
            // margin-bottom: 40px;
            color: #555;
    
            // Small devices (landscape phones, less than 768px)
            @media (max-width: 768px) {
                font-size: 15px;
            }
        }
    
    
        .search-bar {
            width: 100%;
            max-width: 600px;
            display: flex;
            border-radius: 25px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;

            @media (max-width: 768px) {
                max-width: 250px;
                background-color: $black;
                flex-direction: column;
                // overflow: visible;
            }
    
    
            input {
                width: 100%;
                padding: 15px;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: $font-light;
            }
    
    
            button {
                padding: 15px;
                background-color: #417abc;
                color: #fff;
                border: none;
                cursor: pointer;
                font-size: 16px;
                font-family: 'Ubuntu', sans-serif;
            }
    
    
            button:hover {
                background-color: #365f96;
            }
        }
    }
}


// .nav-link {
//     padding: 10px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     a {
//         text-decoration: none;
//         color: $dark;
//         padding: 10px 15px;
//         text-align: center;
//         margin: 0 10px;
//         border-radius: 5px;
//         transition: background-color 0.3s, color 0.3s;
//     }

//     a.active {
//         color: $white;
//         background-color: $secondary-color;
//     }
// }