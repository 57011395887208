@import './fonts';
@import './variables';

.checkout {
    margin: auto;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 576px) {
        margin: 0;
    }


    .content {
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;


        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}