@import '../../../styles/fonts';
@import '../../../styles/variables';


.adscity-pay {


    .pay-modal {
        background-color: $white;
        padding: 20px;
        width: 100%;

        // @media (max-width: 576px) {
        //     width: auto;
        // }

        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            color: $dark;
            font-weight: $font-regular;
            font-family: 'Ubuntu', sans-serif;
        }

        .payment-method {
            margin: 10px 0;

            label {
                font-size: 15px;
                font-weight: $font-light;
            }

            .payment-select-provider {
                margin-top: 10px;

                select {
                    border: none;
                    width: 90%;
                    outline: none;
                    border-bottom: 1px solid $dark;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: $font-light;
                    font-family: 'Ubuntu', sans-serif;

                    option {
                        font-weight: $font-light;
                    }
                }

                .error-message {
                    color: $danger;
                    font-weight: $font-light;
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
        }

        .error-message {
            color: $danger;
            font-weight: $font-light;
            font-size: 13px;
            margin-top: 5px;
        }


        .payment-info {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12px;
                padding: 10px 0;
                display: flex;
                font-weight: $font-light;
                flex-direction: column;
            }

            input {
                border: none;
                border-bottom: 1px solid $dark;
                width: 90%;
                outline: none;
                padding: 10px 0;
                font-size: 14px;
                font-weight: $font-regular;
                font-family: 'Ubuntu', sans-serif;
            }

            .error-message {
                color: $danger;
                font-weight: $font-light;
                font-size: 13px;
                margin-top: 5px;
            }
        }

        .submit-btn {
            cursor: pointer;
            padding: 10px;
            margin: auto;
            width: 90%;
            font-size: 15px;
            background-color: $two;
            font-weight: $font-regular;
            color: $white;
            border-radius: 8px;
            border: none;
            margin-top: 15px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
            font-family: 'Ubuntu', sans-serif;
        }
    }
}