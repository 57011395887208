@import './fonts';
@import './variables';


.ad-tabs {
    .tabs-header {
        display: flex;
        gap: 2px;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        overflow-y: auto;
        scrollbar-width: thin;

        .tab-button {
            padding: 12px 24px;
            border: none;
            background: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            color: $dark;
            font-family: 'Ubuntu', sans-serif;
            font-size: 14px;

            &.active {
                color: $two;
                border-bottom: 2px solid $two;
            }

            &:hover {
                background: #f0f2f5;
            }
        }
    }

    .tab-content-container {
        padding: 20px;

        .tab-content {
            // display: flex;
            // flex-direction: column;

            label {
                display: flex;
                font-size: 14px;
                margin: 8px 0;
                font-weight: $font-regular;
                // flex-direction: column;
            }

            select {
                appearance: none;
                width: 100%;
                padding: 8px;
                outline: none;
                font-family: 'Ubuntu', sans-serif;
                font-weight: $font-light;
                font-size: 14px;
            }

            input,
            textarea {
                width: 95%;
                padding: 8px;
                outline: none;
                font-family: 'Ubuntu', sans-serif;
                font-weight: $font-light;
                font-size: 14px;
            }
        }

        .image-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 10px;



            .image-wrapper {
                position: relative;
                width: 120px;
                height: 120px;
                border-radius: 8px;
                overflow: hidden;
                border: 2px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f9f9f9;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }

            .delete-btn, .replace-btn {
                position: absolute;
                background: rgba(0, 0, 0, 0.6);
                color: white;
                border: none;
                cursor: pointer;
                padding: 5px;
                font-size: 14px;
                border-radius: 5px;
                transition: background 0.3s ease;
            }
    
            .delete-btn {
                top: 5px;
                right: 5px;
            }
        }
    }
}