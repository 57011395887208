@import '../../styles/fonts';
@import '../../styles/variables';


.details {
    .form-navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
    
        .back-button,
        .next-button {
            font-family: 'Ubuntu', sans-serif;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            font-weight: $font-light;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .back-button {
            background-color: #ccc;
            color: #333;
        }
    
        .next-button {
            background-color: $two;
            color: $white;
        }
    }
}