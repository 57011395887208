@import './fonts';
@import './variables';


.toast {
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: 250px;
    background-color: #333;
    color: $white;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0.9;
    animation: slideIn 0.3s ease-in-out;
    font-size: 14px;

    .toast-icon {
        font-size: 20px;
        margin-right: 10px;
    }


    p {
        margin: 0;
    }


    .close {
        cursor: pointer;
        font-size: 20px;
        margin-left: 10px;
    }


    .toast-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0 0 5px 5px;


        .progress {
            height: 100%;
            background-color: $white;
            border-radius: 0 0 5px 5px;
            transition: width 0.1s linear;
        }
    }
}


.toast.info {
    background-color: #007bff;
}

.toast.success {
    background-color: #28a745;
}

.toast.error {
    background-color: #dc3545;
}