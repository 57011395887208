@import'./fonts';
@import'./variables';

.menu {
    position: absolute;
    left: 0;
    top: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 100;
    
    .menu-item {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        
        &:hover {
            background: #f5f5f5;
        }
        
        svg {
            font-size: 14px;
            color: #666;
        }
        
        span {
            color: #333;
            font-weight: $font-light;
            font-size: 13px;
        }
    }
}