@import './fonts';
@import './variables';


.terms {
    padding: 2rem;
    line-height: 1.6;

    h1 {
        font-family: 'Ubuntu',sans-serif;
        color: $dark;
        font-size: 18px;
    }

    h2 {
        font-family: 'Ubuntu',sans-serif;
        color: $dark;
        font-size: 16px;
    }

    p {
        font-family: 'Ubuntu',sans-serif;
        color: $dark;
        font-size: 15px;
        font-weight: $font-light;
        text-align: justify;
    }
}