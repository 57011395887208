@import './fonts';
@import './variables';


.success-message {
    max-width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


    .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        border: 2px solid $success;

        svg {
            font-size: 32px;
            color: $success;
        }
    }

    h2 {
        text-transform: uppercase;
        color: $gray-dark;
        text-align: center;
        font-family: 'Ubuntu',sans-serif;
    }

    p {
        text-align: center;
        color: $gray-dark;
        font-weight: $font-light;
        font-family: 'Ubuntu',sans-serif;
    }

    button {
        border: none;
        cursor: pointer;
        color: $white;
        border-radius: 8px;
        width: 150px;
        padding: 8px 15px;
        font-weight: $font-light;
        font-family: 'Ubuntu',sans-serif;
        background-color: $success;
    }
}

.contactus-form {
    padding: 20px;




    form {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);



        label {
            color: $dark;
            display: flex;
            font-weight: $font-regular;
            flex-direction: column;
            margin-bottom: 0.5rem;


            .input-field {
                margin: 8px 0;
                padding: 8px 15px;
                display: inline-block;
                border: 1px solid $dark;
                outline: none;
                font-size: 15px;
                color: $dark;
                font-weight: $font-light;
                font-family: "Ubuntu", sans-serif;
            }


            .input-field.error {
                background-color: #FF9494;
                border: 1px solid $red;
            }


            option {
                font-weight: $font-light;
            }


            .error-message {
                font-size: 13px;
                font-family: "Ubuntu", sans-serif;
                color: $red;
                margin-bottom: 10px;
                font-weight: $font-regular;
                text-align: start;
            }
        }

        label.checkbox-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 0;
            font-size: 14px;
            font-weight: $font-light;


            .error-message {
                font-size: 13px;
                font-family: "Ubuntu", sans-serif;
                color: $red;
                margin-bottom: 10px;
                font-weight: $font-regular;
                text-align: center;
            }
        }

        button[type=submit] {
            font-family: 'Ubuntu', sans-serif;
            font-size: 15px;
            border: none;
            padding: 10px;
            color: $white;
            border-radius: 5px;
            // width: 150px;
            background-color: $secondary-color;

            span {
                margin-left: 0.25rem;
            }
        }

        .terms-container {
            margin-top: 10px;
            font-size: 14px;
            color: #555;
            text-align: center;
    
            a {
                color: $primary;
                text-decoration: none;
            }
    
            a:hover {
                text-decoration: underline;
            }
        }
    }
}