@import './fonts';
@import './variables';


.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;


    .loading-container {
        background-color: $white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        width: 200px;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        .loading-logo {
            width: 60px;
            height: auto;
            margin-bottom: 20px;
        }

        .loading-header {
            font-size: 24px;
            color: $two;
            font-family: 'Ubuntu',sans-serif;
            font-weight: $font-medium;

            .loading-text {
                height: 30px;
            }
        }
        
        .loading-span {
            margin-top: 1rem;
            // font-size: 17px;
            font-family: 'Ubuntu',sans-serif;
            font-weight: $font-light;
        }
        
        .loading-spinner {
            margin-top: 0.5rem;
            border: 4px solid #ccc;
            border-top-color: #333;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 1s linear infinite;
        }
    }
}