@import '../../styles/fonts';
@import '../../styles/variables';


.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: #282c34;
    padding: 100px 20px;
    text-align: center;
    color: $white;
    
    h1 {
        color: $white;
        font-size: 2rem;
        margin-bottom: 20px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    
    p {
        font-size: 1.2rem;
        max-width: 800px;
        margin: 0 auto;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }

    small {
        font-size: 14px;
        font-weight: $font-light;
    }
}