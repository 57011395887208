@import '../../styles/fonts';
@import '../../styles/variables';


.owner-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 100%;
    // max-width: 200px;
    // margin: auto;
    align-items: center;

    @media (max-width: 768px) {
        width: 90%;
    }


    .owner-image {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;

        .profil-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 10px;
        }
    
        .online-badge {
            position: absolute;
            top: 70px;
            right: 67px;

            width: 8px;
            height: 8px;
            background-color: #4caf50; 
            border-radius: 50%;
            border: 2px solid $white;

            @media (max-width: 768px) {
                right: 150px;
            }
        }
    }

    .profile-type {
        font-family: 'Ubuntu',sans-serif;
        font-weight: $font-regular;
        color: $dark;
    }

    .member-since {
        font-size: 12px;
        color: #666;
    }

    .name {
        font-size: 16px;
        font-weight: $font-regular;
        margin: 5px 0;
    }

    .review {
        font-size: 12px;
        color: $dark;
        margin: 0;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;


        .contact-button {
            background-color: #f5f5f5;
            border: none;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            font-size: 13px;
            font-family: 'Ubuntu', sans-serif;
        

            .icon {
                color: #666;
                font-size: 16px;
            }
        }
    }

    .action-buttons {
        margin-top: 15px;

        .message {
            width: 90%;
            background-color: $two;
            color: $white;
            padding: 8px;
            border-radius: 5px;
            border: none;
            font-weight: $font-light;
            cursor: pointer;
            font-size: 13px;
            font-family: 'Ubuntu', sans-serif;
        }
    }
}