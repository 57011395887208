@import '../../styles/fonts';
@import '../../styles/variables';

.location-form {

    .input-group {
        max-width: 600px;
        width: 100%;
        display: flex;
        margin: auto;
        flex-direction: column;
        font-family: 'Ubuntu', sans-serif;

        .input-label {
            text-align: start;
            color: $gray-dark;
            font-weight: $font-regular;
            padding: 10px 0;

            .compulsory {
                color: red;
            }
        }

        .input-field,
        option {
            outline: none;
            font-size: 15px;
            padding: 10px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;
            appearance: none;
            border: 1px solid $dark;

            &.error {
                border: 1px solid $danger;
                background-color: #ffcccb;
            }
        }

        

        .error-message {
            font-weight: $font-light;
            font-size: 12px;
            color: $danger;
            text-align: start;
        }
    }

    .checkbox-label {
        margin-top: 20px;
        font-weight: $font-light;
    }

    .form-navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
    
        .back-button,
        .next-button {
            font-family: 'Ubuntu', sans-serif;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            font-weight: $font-light;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .back-button {
            background-color: #ccc;
            color: #333;
        }
    
        .next-button {
            background-color: $two;
            color: $white;
        }
    }
}