@import '../../styles/fonts';
@import '../../styles/variables';

.image-upload-form {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .upload-instructions {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-bottom: 10px;
    }

    .upload-area {
        width: 100%;
        max-width: 400px;
        height: 200px;
        border: 2px dashed #bbb;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    
        &:hover {
            background-color: #f9f9f9;
        }
    
        .upload-icon {
            font-size: 50px;
            color: $dark;
        }
    
        .upload-text {
            font-size: 14px;
            color: #666;
            margin-top: 10px;
        }
    }

    .image-upload-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 15px;
        margin-top: 15px;
        width: 100%;
        max-width: 500px;
   
        .image-container {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        
            .uploaded-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        
            .remove-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 18px;
                color: red;
                background: white;
                border-radius: 50%;
                cursor: pointer;
                transition: transform 0.2s ease-in-out;
        
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .form-navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 400px;
        margin-top: 15px;
    
        .back-button,
        .next-button {
            font-family: 'Ubuntu', sans-serif;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            font-weight: $font-light;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .back-button {
            background-color: #ccc;
            color: #333;
        }
    
        .next-button {
            background-color: $two;
            color: $white;
        }
    }
}
