@import './fonts';
@import './variables';


.signup-success-page {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    margin-top: 2rem;
    background-color: $white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Ubuntu', sans-serif;


    h1 {
        color: $dark;
        font-size: 1.3rem;
        font-weight: $font-regular;
    }


    p {
        color: $dark;
        font-weight: $font-light;
        text-align: justify;
    }

    .error {
        margin-top: 0.5rem;
        color: $danger;
        font-size: 14px;
        font-weight: $font-regular;
    }

    .success {
        margin-top: 0.5rem;
        color: $success;
        font-size: 14px;
        font-weight: $font-regular;
    }


    .code-input {
        display: flex;
        justify-content: space-between;


        input {
            width: 35px;
            height: 35px;
            text-align: center;
            font-size: 15px;
            margin: 0 2px;
        }
    }

    a.button {
        display: inline-block;
        padding: 10px 20px;
        background-color: $dark;
        color: $white;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 10px;
    }


    a.button:hover {
        background-color: $gray;
    }


    ul {
        list-style: none;
        margin: 0;
        padding: 0;


        a {
            display: block;
            margin: 10px 0;
            color: $gray-dark;
            text-decoration: none;
        }


        a:hover {
            text-decoration: underline;
        }
    }


    .links a {
        display: block;
        margin: 10px 0;
        color: $gray-dark;
        text-decoration: none;
    }
}