@import './fonts';
@import './variables';


.user-settings {
    background-color: $white;
    border-radius: 8px;

    h2 {
        color: #333;
    }

    section {
        margin-bottom: 30px;

        h2 {
            margin-bottom: 15px;
            font-size: 20px;
            color: #333;
        }

        .social-network-form {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                width: 25px;
                height: 25px;
            }

            input {
                width: 80%;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            input,
            select {
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                font-family: 'Ubuntu', sans-serif;
                color: $dark;
            }

            button {
                padding: 10px;
                border: none;
                border-radius: 5px;
                background-color: $two;
                color: $white;
                font-family: 'Ubuntu', sans-serif;
                cursor: pointer;

                &:hover {
                    background-color: $three;
                }
            }
        }

        .delete-button, 
        .logout {
            margin: 5px 0;
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: #dc3545;
            font-family: 'Ubuntu', sans-serif;
            color: $white;
            width: 100%;
            cursor: pointer;

            &:hover {
                background-color: #a71d2a;
            }
        }

        .help-button {
            margin: 5px 0;
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: $dark;
            font-family: 'Ubuntu', sans-serif;
            color: $white;
            width: 100%;
            cursor: pointer;
        }
    }

    h4 {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
    }

    .ad-details-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-button {
            background-color: transparent;
            color: white;
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;

            &.approve-button {
                background-color: $two;
            }

            &.reject-button {
                background-color: #f44336;
            }
        }
    }
}