@import '../../styles/variables';
@import '../../styles/fonts';

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    .page-btn {
        background: $white;
        border: 1px solid #ddd;
        padding: 8px 14px;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        color: #333;

        &:hover {
            background: #f0f0f0;
        }

        &.active {
            background: $two; // Couleur spéciale pour la page active
            color: $white;
            border: none;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
