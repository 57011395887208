@import './fonts';
@import './variables';

.manage-post {
    margin-top: 4rem; 

    @media (max-width: 768px) {
        margin-top: 8rem;
    }

    .head {
        display: flex;
        align-items: center;
        position: relative;
        // justify-content: space-between;

        h2 {
            color: $dark;
            margin-left: 10px;
        }

        svg {
            color: $dark;
            cursor: pointer;
        }

        .more-options {
            position: absolute;
            cursor: pointer;
            right: 15px;

            align-items: center;
            justify-content: center;
            display: flex;
            text-align: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            
            svg {
                font-size: 18px;
                color: $dark;
            }

            &:hover {
                background: #ddd;
                color: $black;
            }
        }

        .options-menu {
            position: absolute;
            right: 20px;
            top: 60px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.1);
            z-index: 100;
            
            .options-menu-item {
                padding: 12px 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                
                &:hover {
                    background: #f5f5f5;
                }
                
                svg {
                    font-size: 14px;
                    color: #666;
                }
                
                span {
                    color: #333;
                    font-weight: $font-light;
                    font-size: 13px;
                }
            }
        }
    }

    h4 {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
    }

    .ad-details-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-button {
            background-color: transparent;
            color: white;
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;

            &.approve-button {
                background-color: $two;
            }

            &.reject-button {
                background-color: #f44336;
            }
        }
    }
}