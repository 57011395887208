@import './fonts';
@import './variables';

.business {
    padding: 10px 20px;
    text-align: justify;

    h1 {
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        color: $two;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
    }

    h2 {
        font-size: 17px;
        color: $dark;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
    }

    h3 {
        font-size: 16px;
        color: $gray-dark;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
    }

    p {
        font-size: 15px;
        color: $black;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-light;
    }

    .coming-soon {
        text-align: center;
        background-color: $secondary-color;
        padding: 10px 20px;

        h2 {
            text-transform: uppercase;
            color: $white;
        }
    }
}