@import './fonts';
@import './variables';

.user-home {
    .dashboard {
        min-height: 100vh;
        transition: all 0.3s ease;
        
        .content {
            display: flex;
            
            @media (max-width: 768px) {
                flex-direction: column;
            }
            
            .sidebar {
                margin-top: 4rem;
                background: #1a1a1a;
                color: $white;
                padding: 20px;
                transition: all 0.3s ease;
                
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    
                    li {
                        display: flex;
                        align-items: center;
                        position: relative;
                        gap: 12px;
                        padding: 12px;
                        margin: 8px 0;
                        cursor: pointer;
                        border-radius: 8px;
                        transition: all 0.2s ease;
    
                        @media (max-width: 768px) {
                            padding: 8px;
                        }
                        
                        &:hover {
                            background: rgba(255,255,255,0.1);
                        }
                        
                        &.active {
                            background: $two;
                        }

                        a {
                            text-decoration: none;
                            
                            svg {
                                font-size: 15px;
                                color: $white;
                            }
        
                            .label {
                                font-weight: $font-light;
                                font-size: 15px;
                                color: $white;
                                margin-left: 8px;
                            }

                            .badge {
                                background-color: red;
                                color: $white;
                                font-size: 11px;
                                font-weight: bold;
                                border-radius: 50%;
                                padding: 5px 8px;
                                // margin-left: 5px;
                                position: absolute;
                                top: 0;
                                left: 18px;
                            }
                        }
                        
                    }
                }
                
                .collapse-btn {
                    width: 100%;
                    padding: 10px;
                    margin-top: 20px;
                    background: transparent;
                    border: 1px solid rgba(255,255,255,0.2);
                    color: white;
                    border-radius: 6px;
                    cursor: pointer;
                    
                    &:hover {
                        background: rgba(255,255,255,0.1);
                    }
                }
            }
            
            .main-content {
                flex: 1;
                padding: 20px;
                background: #f0f2f5;

                .footer {
                    background-color: #f5f5f5;
                    padding: 10px;
                    text-align: center;
                    font-size: 14px;
                    color: #666;
                    font-weight: $font-light;
                }
            }
        }
        
        &.collapsed {
            .sidebar {
                width: 80px;
                
                li {
                    justify-content: center;
                }
            }
        }
        
        @media (max-width: 768px) {
            .sidebar {
                position: fixed;
                margin-top: 3rem;
                width: 92%;
                z-index: 100;
                
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 10px;
                    
                    li {
                        flex: 0 0 auto;
    
                        .label {
                            display: none;
                        }
                    }
                }
                
                .collapse-btn {
                    display: none;
                }
            }
        }
    }
}

