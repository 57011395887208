@import './fonts';
@import './variables';

.reset-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: $two;

    .reset-form {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid $gray;
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;

        @media (max-width: 768px) {
            width: 250px;
        }

        h2 {
            text-align: center;
            color: $dark;
        }


        label {
            color: $dark;
            display: block;
            font-size: 15px;
            font-weight: $font-regular;
            // margin-top: 1rem;
        }

        label.checkbox-label {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 14px;
            font-weight: $font-light;
        }


        .password-toggle {
            position: relative;


            span {
                position: absolute;
                top: 35px;
                color: $dark;
                right: 0.25rem;
                cursor: pointer;
            }
        }


        .input-field {
            width: 90%;
            margin: 8px 0;
            padding: 8px 15px;
            display: inline-block;
            border: 1px solid $dark;
            outline: none;
            font-size: 15px;
            color: $dark;
            font-weight: $font-light;
            font-family: "Ubuntu", sans-serif;
        }


        .input-field.error {
            background-color: #FF9494;
            border: 1px solid $red;
        }


        .error-text {
            font-size: 13px;
            font-family: "Ubuntu", sans-serif;
            color: $red;
            margin-bottom: 10px;
            font-weight: $font-regular;
            text-align: start;
        }


        button[type=submit] {
            font-family: 'Ubuntu', sans-serif;
            font-size: 15px;
            border: none;
            padding: 10px;
            color: $white;
            background-color: $secondary-color;
        }
    }
}