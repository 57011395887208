@import './fonts';
@import './variables';

.quick-start-guide {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;

    h1 {
        text-align: center;
        margin-bottom: 20px;
        color: $black;
        font-weight: $font-medium;
        font-family: 'Ubuntu', sans-serif;
    }

    p {
        font-size: 1rem;
        text-align: center;
        color: $black;
        margin-bottom: 30px;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
    }

    .steps-list {
        list-style: none;
        padding: 0;
        text-align: center;

        li {
            background: #f9f9f9;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 20px;
            margin-bottom: 20px;
            transition: box-shadow 0.3s ease, transform 0.2s ease;

            &:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                transform: translateY(-2px);
            }

            h3 {
                font-size: 18px;
                margin-bottom: 10px;
                color: $gray-dark;
            }

            p {
                font-size: 15px;
                color: #666;
                margin-bottom: 10px;
                font-family: 'Ubuntu', sans-serif;
                font-weight: $font-regular;
            }

            .link {
                display: inline-block;
                text-decoration: none;
                color: $two;
                 transition: color 0.3s;
                font-family: 'Ubuntu', sans-serif;
                font-weight: $font-regular;в

                &:hover {
                    color: $three;
                }
            }
        }
    }

    .cta-section {
        text-align: center;
        margin-top: 40px;

        h2 {
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 15px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-regular;
        }

        p {
            font-size: 1rem;
            color: #555;
            margin-bottom: 20px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-regular;
        }

        .btn {
            display: inline-block;
            padding: 10px 20px;
            font-size: 1rem;
            color: $white;
            background-color: $two;
            border-radius: 5px;
            text-decoration: none;
            transition: background-color 0.3s;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-regular;

            &:hover {
                background-color: $three;
            }
        }
    }

    // Responsive design
    @media (max-width: 768px) {
        padding: 15px;

        h1 {
            font-size: 1.75rem;
        }

        .steps-list li {
            padding: 15px;
        }

        .cta-section h2 {
            font-size: 1.25rem;
        }
    }
}