@import './fonts';
@import './variables';


.team-page {
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;

    .section-team {
        margin-bottom: 3rem;

        .team-page-title {
            font-size: 2.5rem;
            margin-bottom: 2rem;
            color: $gray-dark;
        }

        .team-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 2rem;
            align-items: center;
            justify-content: center;
            align-items: stretch;

            .team-member {
                background: white;
                border: 1px solid #ddd;
                border-radius: 8px;
                padding: 1.5rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                transition: transform 0.2s ease-in-out;

                .team-member-photo {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-bottom: 1rem;
                }
                
                .team-member-name {
                    font-size: 1.25rem;
                    font-weight: bold;
                    color: $two;
                    margin: 0.5rem 0;
                }
                
                .team-member-role {
                    font-size: 1rem;
                    color: #555;
                    margin-bottom: 1rem;
                    font-weight: $font-regular;
                }
                
                .team-member-description {
                    font-size: 0.875rem;
                    color: #666;
                    line-height: 1.5;
                    font-weight: $font-light;
                }
            }
            
            .team-member:hover {
                transform: translateY(-10px);
            }
        }
    }

    .section-mission {
        margin: 3rem 0;

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
            color: $gray-dark;
        }

        p {
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
            max-width: 800px;
            margin: 0 auto;
            font-weight: $font-light;
        }
    }

    .section-values {
        margin: 3rem 0;

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
            color: $gray-dark;
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                font-size: 1rem;
                color: #555;
                margin: 0.5rem 0;
                font-weight: $font-light;

                strong {
                    color: $two;
                }
            }
        }
    }

    .section-history {
        margin: 3rem 0;

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
            color: $gray-dark;
        }

        p {
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
            max-width: 800px;
            margin: 0 auto;
            font-weight: $font-light;
            text-align: justify;
        }
    }
}