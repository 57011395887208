@import '../../styles/variables';
@import '../../styles/fonts';

.nav-links {
    height: 40px;
    width: 40px;
    margin: 0 20px;
    cursor: pointer;


    .profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid $white;
    }
}