@import './fonts';
@import './variables';


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;


    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        max-width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        animation: slideDown 0.3s ease-out;



        // Media queries
        @media (max-width: 768px) {
            // display: none;
        }

        @media (max-width: 480px) {
            width: 300px;
        }


        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e9ecef;
            padding-bottom: 10px;
            margin-bottom: 20px;


            .modal-title {
                margin: 0;
                font-size: 18px;
                font-weight: $font-regular;
                text-transform: uppercase;
            }
            
            .close-button {
                background: transparent;
                border: none;
                font-size: 24px;
                cursor: pointer;
                color: #6c757d;
                
                &:hover {
                    color: #343a40;
                    background: #f8f9fa;
                }
            }
        }


        .modal-body {
            margin-top: 10px 0;
        }


        .modal-footer {
            display: flex;
            padding-top: 10px;
            border-top: 1px solid #e9ecef;


            .modal-button {
                padding: 8px 15px;
                color: $white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 14px;
                font-weight: $font-light;
                font-family: 'Ubuntu',sans-serif;
            }

            .modal-button.close {
                background-color: #f44336;
            }

            .modal-button.next {
                background-color: $primary-color;
            }
            
        }

        .modal-footer.space-between {
            justify-content: space-between;
        }

        .modal-footer.flex-end {
            justify-content: flex-end;
        }
    }
}