@import '../../../styles/fonts';
@import '../../../styles/variables';

.pay-instruction {


    .instruction {
        background-color: $white;
        padding: 20px;
        // width: 100%;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            color: $dark;
            font-weight: $font-regular;
            font-family: 'Ubuntu', sans-serif;
            text-align: center;
        }

        h4 {
            font-size: 16px;
            margin-bottom: 10px;
            color: $dark;
            font-weight: $font-regular;
            font-family: 'Ubuntu', sans-serif;
            text-align: center;
        }

        p {
            font-size: 14px;
            font-weight: $font-light;
            font-family: 'Ubuntu', sans-serif;
            text-align: center;
        }


        .btns-btn {
            margin-top: 2rem;
            display: flex;
            justify-content: space-around;


            button {
                cursor: pointer;
                padding: 10px;
                font-size: 14px;
                font-weight: $font-light;
                font-family: 'Ubuntu', sans-serif;
                border-radius: 8px;
                border: none;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
            }

            button#copy-number {
                color: $white;
                background-color: $secondary-color;
            }

            button#deposit-done {
                color: $white;
                background-color: $success;
            }
        }
    }
}
