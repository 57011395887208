@import '../../styles/fonts';
@import '../../styles/variables';


.notification-item {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    position: relative;
    margin-bottom: 1rem;


    .avatar-container {
        display: flex;
        align-items: start;

        img {
            width: 50px;
            height: 50px;
        }


        .username-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            h3 {
                font-weight: $font-regular;
                color: $two;
                font-size: 16px;
                margin-left: 10px;
            }

            .verified-badge {
                margin-left: 10px;
                color: $two;
            }
        }

        .more-options {
            position: absolute;
            cursor: pointer;
            right: 15px;

            align-items: center;
            justify-content: center;
            display: flex;
            text-align: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            svg {
                font-size: 18px;
                color: $dark;
            }

            &:hover {
                background: #ddd;
                color: $black;
            }
        }

        .options-menu {
            position: absolute;
            right: 20px;
            top: 40px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            z-index: 100;

            .options-menu-item {
                padding: 12px 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                &:hover {
                    background: #f5f5f5;
                }

                svg {
                    font-size: 14px;
                    color: #666;
                }

                span {
                    color: #333;
                    font-weight: $font-light;
                    font-size: 13px;
                }
            }
        }
    }

    .notification-content {
        margin-bottom: 20px;

        .title {
            font-size: 15px;
            font-weight: $font-light;
            margin: 0;
        }

        .type {
            font-weight: $font-light;
            margin: 0;
            font-size: 14px;
        }

        .timestamp {
            font-weight: $font-light;
            font-size: 12px;
            color: $gray;
        }
    }

    h4 {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
    }

    .ad-details-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-button {
            background-color: transparent;
            color: white;
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;

            &.approve-button {
                background-color: $two;
            }

            &.reject-button {
                background-color: #f44336;
            }
        }
    }
}

.notification-item.unread {
    border-left: 3px solid $two;
}

.notification-item.read {
    border-left: 3px solid $white;
}

.notification-list {
    margin-top: 4rem;
    padding: 10px 20px;

    .head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $dark;
        }

        .more-options {
            position: absolute;
            cursor: pointer;
            right: 15px;

            align-items: center;
            justify-content: center;
            display: flex;
            text-align: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            svg {
                font-size: 18px;
                color: $dark;
            }

            &:hover {
                background: #ddd;
                color: $black;
            }
        }

        .options-menu {
            position: absolute;
            right: 20px;
            top: 50px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            z-index: 100;

            .options-menu-item {
                padding: 12px 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                &:hover {
                    background: #f5f5f5;
                }

                svg {
                    font-size: 14px;
                    color: #666;
                }

                span {
                    color: #333;
                    font-weight: $font-light;
                    font-size: 13px;
                }
            }
        }
    }

    h4 {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        color: $dark;
        font-weight: $font-light;
    }

    .ad-details-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-button {
            background-color: transparent;
            color: white;
            padding: 6px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-family: 'Ubuntu', sans-serif;
            font-weight: $font-light;

            &.approve-button {
                background-color: $two;
            }

            &.reject-button {
                background-color: #f44336;
            }
        }
    }



    @media (max-width: 768px) {
        margin-top: 9rem;
        width: 100%;
        padding: 0;
    }
}