@import '../../styles/fonts';
@import '../../styles/variables';


.payment-intents {
    padding: 20px;
    background-color: $white;
    margin-top: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            color: $dark;
        }

        .more-options {
            position: absolute;
            cursor: pointer;
            right: 15px;

            align-items: center;
            justify-content: center;
            display: flex;
            text-align: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            svg {
                font-size: 18px;
                color: $dark;
            }

            &:hover {
                background: #ddd;
                color: $black;
            }
        }

        .options-menu {
            position: absolute;
            right: 20px;
            top: 50px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            z-index: 100;

            .options-menu-item {
                padding: 12px 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                &:hover {
                    background: #f5f5f5;
                }

                svg {
                    font-size: 14px;
                    color: #666;
                }

                span {
                    color: #333;
                    font-weight: $font-light;
                    font-size: 13px;
                }
            }
        }
    }

    .table-container {
        width: 100%;
        overflow-x: auto;

        @media (max-width: 768px) {
            overflow-x: auto;
            border-radius: 5px;
            padding-bottom: 10px;
        }

        table {
            width: 100%;
            min-width: 600px;
            /* Ajuste la largeur minimale selon tes besoins */
            border-collapse: collapse;
            background-color: $white;

            th,
            td {
                padding: 12px;
                text-align: left;
                font-weight: $font-light;
                border-bottom: 1px solid #ddd;
                white-space: nowrap;
                /* Évite les retours à la ligne */
            }

            thead {
                color: $dark;
                background-color: #f5f5f5;

                th {
                    text-transform: uppercase;
                    font-weight: $font-regular;
                }
            }

            tbody tr:nth-child(even) {
                background-color: #f9f9f9;
            }

            tbody tr:hover {
                background-color: #f1f1f1;
            }
        }
    }
}