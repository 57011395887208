@import './fonts';
@import './variables';


.ad-details {
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        padding: 0;
    }

    .display {
        max-width: 800px;
        margin: auto;
    }

    .under_score {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-around;


        @media (max-width: 576px) {
            flex-direction: column;
        }

        .title {
            text-align: center;

            @media (max-width: 576px) {
                margin: 10px 0;
            }

            span {
                color: $dark;
                font-size: 32px;
                letter-spacing: 1px;
                font-weight: $font-regular;

                @media (max-width: 480px) {
                    font-size: 18px;
                }
            }

            p {
                color: $gray-dark;
                margin: 0;
                display: flex;
                align-items: center;
                font-weight: $font-light;
                font-size: 18px;

                @media (max-width: 480px) {
                    font-size: 15px;
                }

                .icon {
                    margin: 0 5px;
                }
            }
        }

        .price {
            text-align: center;

            @media (max-width: 576px) {
                margin: 10px 0;
            }

            span {
                color: $gray-dark;
                margin: 0;
                font-weight: $font-light;
            }

            p {
                margin: 0;
                color: $dark;
                font-size: 32px;
                letter-spacing: 1px;
                font-weight: $font-regular;
            }
        }
    }

    .image-gallery {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 600px; // Ajuste selon ta mise en page
        margin: 0 auto;

        @media (max-width: 576px) {
            max-width: 400px;
        }

        .main-image-container {
            position: relative;
            width: 100%;
            height: 400px; // Ajuste la hauteur
            overflow: hidden;

            .main-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.5s ease; // Animation simple
            }

            .nav-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(0, 0, 0, 0.3);
                border: none;
                color: #fff;
                font-size: 2rem;
                width: 40px;
                height: 40px;
                cursor: pointer;
                z-index: 2;

                &.left {
                    left: 0;
                }

                &.right {
                    right: 0;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .thumbnails {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 10px;
            gap: 8px;

            .thumbnail {
                width: 60px;
                height: 60px;
                overflow: hidden;
                cursor: pointer;
                border: 2px solid transparent;
                transition: border-color 0.3s;

                &.active {
                    border-color: $secondary-color;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }
        }
    }

    .sold-badge {
        background-color: red;
        color: $white;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        font-weight: $font-regular;
        font-family: 'Ubuntu', sans-serif;
        text-transform: uppercase;
        border-radius: 5px;
    }


    .content {
        width: 100%;
        // max-width: 1200px;
        // margin: auto;

        display: flex;
        justify-content: space-between;


        @media (max-width: 768px) {
            max-width: 400px;
            display: flex;
            flex-direction: column;
        }

        .detail-section {
            background: #fff;
            padding: 15px;
            border-radius: 8px;

            .details,
            .description,
            .features,
            .location,
            .meta-info {
                margin: 20px 0;
            }

            .contact-seller {
                display: block;
                padding: 10px 20px;
                background-color: #2196F3;
                color: $white;
                text-align: center;
                font-size: 18px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
            }

            h2 {
                font-size: 18px;
                text-transform: uppercase;
                background-color: #ddd;
                padding: 5px;
                font-weight: $font-regular;
                color: $dark;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    padding: 5px 0;
                    font-weight: $font-light;
                }
            }

            p {
                font-weight: $font-light;
            }
        }


        .owner {
            background: #fff;
            padding: 15px;
            border-radius: 8px;

            // @media (max-width: 576px) {
            //     max-width: 400px;
            //     width: 100%;
            // }
        }
    }
}