@import '../../styles/fonts';
@import '../../styles/variables';


.step-indicator {
    text-align: center;
    margin: 20px 0;

    .step-count {
        color: $dark;
        font-size: 14px;
        margin-bottom: 8px;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
    }

    .step-title {
        color: #333;
        font-size: 24px;
        font-weight: $font-regular;
        margin: 0;
    }
}