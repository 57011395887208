@import "../../styles/fonts";
@import '../../styles/variables';


.filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    background-color: #f8f9fa; 
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .tab {
        font-family: 'Ubuntu', sans-serif;
        font-size: 1rem;
        text-align: center;
        font-weight: $font-light;
        color: $dark;
        padding: 0.5rem 1rem;
        border-bottom: 2px solid transparent; 
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            color: $three;
        }
    }

    .active {
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-regular;
        color: $two; 
        border-bottom: 2px solid $two; 
    }

    .filter-icon {
        color: $two;
        cursor: pointer;
        font-size: 16px;
        transition: color 0.3s ease;

        &:hover {
            color: $three;
        }
    }
}
